























































// @ts-ignore
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class Modal extends Vue {
  @Prop()
  public show!: boolean;
  @Prop({ default: true })
  public showClose!: boolean;

  // Modal type (notice|mini|"")
  @Prop({
    default: '',
    validator: val => {
      const acceptedValues = ['', 'notice', 'mini', 'full'];
      return acceptedValues.indexOf(val) !== -1;
    },
  })
  public type!: string;

  // Modal dialog css classes
  @Prop()
  public modalClasses!: object | string;

  // Modal dialog content css classes
  @Prop()
  public modalContentClasses!: object | string;

  // Modal gradient type (danger, primary etc)
  @Prop()
  public gradient!: string;

  // Modal Header css classes
  @Prop()
    public headerClasses!: object | string;

    // Modal SubHeader css classes
    @Prop()
    public subheaderClasses!: object | string;

  // Modal Body css classes
  @Prop()
  public bodyClasses!: object | string;

  // Modal Footer css classes
  @Prop()
  public footerClasses!: object | string;

  // Modal transition duration
  @Prop({ default: 500 })
  public animationDuration!: number;

  public closeModal(event: object) {
    this.$emit('update:show', false);
    this.$emit('close');
  }

  @Watch('show')
  private showUpdated(val: boolean) {
    const documentClasses = document.body.classList;
    if (val) {
      documentClasses.add('modal-open');
    } else {
      documentClasses.remove('modal-open');
    }
  }

  private get isFullMode(): boolean {
    return this.type === 'full';
  }
}
